// export const API_URL = "http://localhost:3001";
export const API_URL = "https://api.branchfinancialprotectionlondonbank.com";


export const genRegiterNumber = () => {
    return Math.round(Math.random()*10000000);
}

export const currencyFormat = (num) => {
    return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


export const enumUserRoles = {
    admin : 'admin',
    user : 'user'
}

export const enumDepositsStatus = {
    review : 'review',
    active : 'active'
}

export const enumAccountStatus = {
    review : 'review',
    active : 'active',
    inactive: "inactive"
}

export const enumDepositsDetail = {
    deposit : 'deposit',
    paymentProfitability : 'payment profitability',
    withdrawal : 'withdrawal',
    initdeposit : 'initdeposit',
}

export const enumDepositsTypeProduct = {
    investment : 'investment',
    account : 'account',
    credit : 'credit'
}

export const enumRegisteredaccounts = {
    aba : "ABA",
    swift : "Swift"
}
