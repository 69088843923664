import React, { Suspense, Component } from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { enumUserRoles } from './constants.js';
import PrivateRoute from './helper/PrivateRoutes';
// Pages

const ForgetPassword = React.lazy(() => import("./components/pages/ForgetPassword"));
const ResetPassword = React.lazy(() => import("./components/pages/ResetPassword"));
const Error = React.lazy(() => import("./components/pages/Error"));
const Login = React.lazy(() => import("./components/pages/Login"));


const InvestmentoptionsrequestAdmin = React.lazy(() => import("./components/pages/InvestmentoptionsrequestAdmin"));
const InvestmentoptionsrequestAdminNew = React.lazy(() => import("./components/pages/InvestmentoptionsrequestAdminNew"));
const RegisterAccountUserDashboard = React.lazy(() => import("./components/pages/RegisterAccountUserDashboard"));
const RegisterTransferUserDashboard = React.lazy(() => import("./components/pages/RegisterTransferUserDashboard"));
const AccounttyperequestAdmin = React.lazy(() => import("./components/pages/Accounttyperequestadmin"));
const Userdashboard = React.lazy(() => import("./components/pages/Userdashboard"));
const Admindashboard = React.lazy(() => import("./components/pages/Admindashboard"));
const Admindashboarddetail = React.lazy(() => import("./components/pages/Admindashboarddetail"));
const AdmindashboardUserDetail = React.lazy(() => import("./components/pages/AdmindashboardUserDetail"));
const Accounttyperequest = React.lazy(() => import("./components/pages/Accounttyperequest"));

const Request = React.lazy(() => import("./components/pages/Request"));

function App() {

  const { user } = useSelector((state) => state.user)
const history = useHistory()

  return (
    <div className="main-body">
      <Router >
        <Suspense fallback={<div></div>}>
          <Switch>
            <Route exact path="/"  component={Login} />
            
            
            <Route exact path="/fplaccount-request/:id" component={Accounttyperequest} />

            <Route exact path="/fplaccount-response/:id" component={Request} />

            <Route exact path="/login" component={Login} />
            
            <Route exact path="/forget-password" component={ForgetPassword} />
            <Route exact path="/reset-password/:id" component={ResetPassword} />


            <PrivateRoute exact path="/finance-dashboard" role={[enumUserRoles.admin, enumUserRoles.user]}
              component={
                user?.role === enumUserRoles.admin ? Admindashboard : Userdashboard
              } />

            <PrivateRoute exact path="/register-account" role={[enumUserRoles.user]} component={RegisterAccountUserDashboard} />
            <PrivateRoute exact path="/transfers" role={[enumUserRoles.user]} component={RegisterTransferUserDashboard} />

            <PrivateRoute exact role={[enumUserRoles.admin]} path="/finance-dashboard-detail/:id" component={Admindashboarddetail} />
            <PrivateRoute exact role={[enumUserRoles.admin]} path="/investment-options-request" component={InvestmentoptionsrequestAdmin} />
            <PrivateRoute exact role={[enumUserRoles.admin]} path="/investment-options-request-user/:id" component={InvestmentoptionsrequestAdmin} />
            <PrivateRoute exact role={[enumUserRoles.admin]} path="/investment-options-request-new" component={InvestmentoptionsrequestAdminNew} />
            <PrivateRoute exact role={[enumUserRoles.admin]} path="/account-type-request" component={AccounttyperequestAdmin} />
            <PrivateRoute exact role={[enumUserRoles.admin]} path="/finance-dashboard-account-detail/:id" component={AdmindashboardUserDetail} />


            <Route exact path="/error" component={Error} />
            <Route exact  component={Error} />
            


          </Switch>
        </Suspense>
      </Router>
    </div>
  );
}


export default App;
