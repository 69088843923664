import { call, put, takeEvery, takeLatest, all, take, select, fork, actionChannel, cancelled, race, delay } from 'redux-saga/effects'
import { END, buffers, eventChannel } from 'redux-saga'

import axios from 'axios';
import { API_URL } from '../constants';

import { updateToken, logout } from './user'
import { setTime } from './data'
import html_themeplate from '../assets/themeplates/close-session.min.txt'



function validateSession() {
    return eventChannel(emitter => {

        const iv = setInterval(() => {
            emitter("")
        }, 1000);

        return () => {
            clearInterval(iv);
        }
    })
}



// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* countSegs() {
    yield put(setTime());
}


// worker Saga: will be fired on USER_FETCH_REQUESTED actions
function* validateSessionApi() {
    try {

        const config = yield select(state => state.data);
        const userData = yield select(state => state.user);
        const { jwtToken, sessionData, loginDate } = userData;
        const { sessionTime } = config;

        if (!jwtToken || !sessionData || !loginDate) {
            throw new Error('Corrupted session');
        }

        const currentDate = (new Date()).getTime();
        const secondsExp = ((currentDate - loginDate) / 1000);

        let session_notification = window.localStorage.getItem("session_notification");

        if (secondsExp > (sessionTime * 0.7) && document.visibilityState !== 'visible' && session_notification !== "1") {
            window.localStorage.setItem("session_notification", "1");
            yield put({ type: "session_notification" })
            yield call(popNotification);
        }
        
        if (secondsExp >= sessionTime) {
            window.localStorage.removeItem("session_notification");
            throw new Error('Session Expired');
        }

        return secondsExp > (sessionTime * 0.5);


    } catch (e) {
        // console.log(e)
        yield put({ type: "CLOSE-SESSION" })
        return false;
    }
}


function* resetToken() {
    try {
        const userData = yield select(state => state.user);
        const { jwtToken, sessionData } = userData;

        const user = yield axios.post(`${API_URL}/auth/validate-session`, {
            session: sessionData
        }, {
            headers: {
                Authorization: `Bearer ${jwtToken}`
            }
        })

        if (!user || !user.data)
            throw new Error("Invalid response server");

        window.localStorage.removeItem("session_notification");
        
        yield put(updateToken(user.data.access_token));

    } catch (e) {
        // console.log(e)
        yield put({ type: "CLOSE-SESSION" })
    }
}

function* popNotification() {
    try {

        
        fetch(html_themeplate)
        .then(res => res.text() )
        .then(res => {
            let winUrl = URL.createObjectURL(
                new Blob([res], { type: "text/html" })
            );
    
            window.open(
                winUrl,
                "Unite Kingdom Financial Protection London Bank Notificatión",
                `width=600,height=300,screenX=200,screenY=200,location=0,menubar=0,status=0,scrollbars=0`
            );
         })
        

        

    } catch (e) { 
        // console.log(e)
    }
}


function* refreshAuthSession() {
    yield call(countSegs);

    const watchSession = yield call(validateSessionApi);
    if (!watchSession || document.visibilityState !== 'visible')
        return;

    
    yield call(resetToken);

}


export function* validateSessionSaga() {
    const { payload } = yield take('INIT-SESSION');

    const chan = yield call(validateSession)

    try {
        while (true) {
            const { logoutAction, socketAction } = yield race({
                logoutAction: take('CLOSE-SESSION'),
                socketAction: take(chan)
            });

            if (logoutAction) {
                chan.close()
                yield fork(validateSessionSaga);
                yield fork(forceCloseSession);
                // yield take(END);
            } else {
                yield fork(refreshAuthSession)
            }
        }
    } finally {
        if (yield cancelled()) {
            chan.close()
        }

    }
}


function* forceCloseSession() {
    try {
        yield put(logout());
        // window.location.reload();
        window.location.href = "https://financialprotectionlondonbank.com/";

    } catch (e) {
        // console.log(e);
    }
}

export function* forceCloseSessionSaga() {
    yield takeEvery('FORCE-CLOSE-SESSION', forceCloseSession);
}

export function* initTriggerReload() {
    yield delay(5000);
    const userData = yield select(state => state.user);
    const { jwtToken, sessionData, loginDate } = userData;

    if (jwtToken && sessionData && loginDate)
        yield put({ type: "INIT-SESSION" });
}

